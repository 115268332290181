import * as React from 'react';
import styledComponents from 'styled-components';
import { Layout } from '../components/layout';
import Banner from '../components/banners';
import Contact from '../components/contact';

const TopContainer = styledComponents.div`
  background: transparent linear-gradient(180deg, #FFFFFF00 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  padding-bottom: 40px;
  margin-bottom: 0;
  position: relative;
`;

const meta = [
  <meta key='description' name='description' content={'Dados de contato e localização da Clínica Dermatológica Angelucci.'} />,
];

const ContactPage = () => (
  <Layout title={'Clínica Dermatológica Angelucci - Contato'} meta={meta}>
    <main>
      <TopContainer>
        <Banner name='contato'>
          conheças as unidades e
          <strong>fale conosco</strong>
        </Banner>
        <Contact />
      </TopContainer>
    </main>
  </Layout>
);

export default ContactPage;
