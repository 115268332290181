import React from 'react';
import styledComponents from 'styled-components';
import { ContentDivisor } from '../divisor';
import { H2 } from '../titles';
import { ContactUnits } from './units';

const DashedCircle = styledComponents.div`
  position: absolute;
  top: 235px;
  right: calc(50% - 1200px);
  width: 1000px;
  height: 1000px;
  border: 3px dashed #D0AD67;
  opacity: 1;
  border-radius: 500px;
  z-index: 0;
`;

const Contact = () => (
  <>
    <ContentDivisor />
    <H2>Conheça nossas Unidades</H2>
    <ContactUnits />
    <DashedCircle />
  </>
);

export default Contact;
