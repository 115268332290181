import React from 'react';
import styledComponents from 'styled-components';
import GeneralDefinitions from '../../config/generalDefinitions';
import { arrayToolsFor } from '../../utilities/dataManagement';
import THEME from '../../themes';
import { PrimaryLinkButtonWithoutGatsby } from '../button';
import { WhatsappIcon } from '../icons';

const { units } = GeneralDefinitions;

const getPhoneStringFrom = (phones) => {
  const phonesGroup = arrayToolsFor(phones).group('number', 'ddd');

  return Object.keys(phonesGroup)
    .reduce((acc, ddd) => `${acc} (${ddd}) ${phonesGroup[ddd].join(' / ')}`, '');
};

const UnitsContainer = styledComponents.div`
  text-align: left;
  font: ${THEME.TEXT.BODY.FONT};
  color: ${THEME.TEXT.BODY.COLOR};
`;

const UnitName = styledComponents.div`
  font: ${THEME.TEXT.LEAD_TOPIC.FONT};
  text-transform: ${THEME.TEXT.LEAD_TOPIC.TEXT_TRANSFORM};
  color: ${THEME.COLORS.PRIMARY};
  font-weight: bold;
`;
const UnitAddress = styledComponents.div``;
const UnitPhones = styledComponents.div``;

const Unit = styledComponents.div`
  margin-top: 50px;
  margin-bottom: 40px;
  position:relative;
  z-index: 1;

  .mapouter {
    position:relative;
    text-align:right;
    height:350px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .gmap_canvas {
    overflow:hidden;
    background:none!important;
    height:350px;
    width:100%;
  }

  .unitData {
    padding: 24px;
    display: flex;
    width: 100%;
    max-width: 1000px;
    margin: 30px auto;
    justify-content: space-between;
    align-items: center;

    a {
      max-height: 30px;
    }

    @media screen and (max-width: 735px){
      flex-wrap: wrap;

      .text {
        width: 100%;
      }

      a {
       margin-top: 10px;
      }
    }

    @media screen and (max-width: 400px){
      max-width: 80%;
    }
  }
`;

const createUnit = (unit) => (
  <Unit key={unit.name}>
    <div className='unitData'>
      <div className='text'>
        <UnitName>{unit.name}</UnitName>
        <UnitAddress>
          {unit.address.substring(0, unit.address.indexOf(','))}<br />
          {unit.address.substring(unit.address.indexOf(',') + 1)}
        </UnitAddress>
        <UnitPhones>{getPhoneStringFrom(unit.phones)}</UnitPhones>
      </div>
      <PrimaryLinkButtonWithoutGatsby href={`https://api.whatsapp.com/send?phone=${unit.whatsapp}`} target='_blank' rel="noreferrer">
        <WhatsappIcon color={ THEME.COLORS.SECONDARY } /> Marque uma consulta
      </PrimaryLinkButtonWithoutGatsby>
    </div>
    <div className="mapouter">
      <div className="gmap_canvas">
        <iframe
          style={{ width: '100%' }}
          height="350"
          id= {`gmap_canvas_${unit.name}`}
          src={unit.mapEmbedded}
          frameBorder="0"
          scrolling="no"
          marginHeight="0"
          marginWidth="0"
          allowFullScreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe><br />
      </div>
    </div>
  </Unit>
);

export const ContactUnits = () => (
  <UnitsContainer>
    {units.map(createUnit)}
  </UnitsContainer>
);
